import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';
import { whitenoise } from './utils/white-noise';
import { MainNavigationComponent } from './components/main-navigation/main-navigation.component';

@Component({
  standalone: true,
  imports: [RouterModule, MainNavigationComponent],
  selector: 'peca-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements AfterViewInit {
  src: string;

  constructor() {
    this.src = whitenoise;
  }

  ngAfterViewInit() {
    this.forceReplay();
    this.keepAlive();
  }

  private forceReplay() {
    const video = document.querySelector('video');

    if (!video) return;

    window.setInterval(async () => {
      await video.play();
      video.pause();
      await video.play();
    }, 5000);
  }

  private async keepAlive() {
    try {
      await navigator.wakeLock.request('screen');
    } catch (e) {
      alert(JSON.stringify(e));
    }
  }
}

export const environment = {
  reporstRefreshTimeout: 10000 * 60,
  keycloak: {
    config: {
      url: 'https://accounts.qa.peca.ai',
      realm: 'pecaai',
      clientId: 'reports-admin',
    },
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
    },
  },
};

import { Route } from '@angular/router';
import { KeycloakAuthComponent, keycloakGuard } from '@peca/keycloak';
import { ReportsComponent } from './components/reports/reports.component';
import { ReceitaComponent } from './components/reports/rl-receita/rl-receita.component';
import { ResultadoComponent } from './components/reports/rl-resultado/rl-resultado.component';
import { OperacaoComponent } from './components/reports/rl-operacao/rl-operacao.component';
import { ProdutosComponent } from './components/reports/rl-produtos/rl-produtos.component';
import { EntregasComponent } from './components/reports/rl-entregas/rl-entregas.component';
import { DiarioComponent } from './components/reports/rl-diario/rl-diario.component';
import { ComercialComponent } from './components/reports/rl-comercial/rl-comercial.component';
import { SellerComponent } from './components/reports/rl-seller/rl-seller.component';
import { IntegracoesComponent } from './components/reports/rl-integracoes/rl-integracoes.component';
import { CarrinhoComponent } from './components/reports/rl-carrinho/rl-carrinho.component';
import { MainNavigationComponent } from './components/main-navigation/main-navigation.component';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    component: KeycloakAuthComponent,
  },
  {
    path: '',
    component: MainNavigationComponent,
    canActivate: [keycloakGuard(['reports-view'], true)],
    children: [
      {
        path: 'diario',
        component: DiarioComponent,
      },
      {
        path: 'receita',
        component: ReceitaComponent,
      },
      {
        path: 'resultado',
        component: ResultadoComponent,
      },
      {
        path: 'operacao',
        component: OperacaoComponent,
      },
      {
        path: 'produtos',
        component: ProdutosComponent,
      },
      {
        path: 'entregas',
        component: EntregasComponent,
      },
      {
        path: 'comercial',
        component: ComercialComponent,
      },
      {
        path: 'seller',
        component: SellerComponent,
      },
      {
        path: 'integracoes',
        component: IntegracoesComponent,
      },
      {
        path: 'carrinho',
        component: CarrinhoComponent,
      },
    ],
  },
];

import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { environment } from '../../../../environments/environment';

@Component({
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rl-integracoes.component.html',
  styleUrls: ['./rl-integracoes.component.scss'],
})
export class IntegracoesComponent implements AfterViewInit {
  @ViewChild('frame')
  frame!: ElementRef;

  ngAfterViewInit() {
    const { src } = this.frame.nativeElement;
    const { reporstRefreshTimeout } = environment;

    window.setInterval(
      () => (this.frame.nativeElement.src = src),
      reporstRefreshTimeout
    );
  }
}

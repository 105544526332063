import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { DesignSystemModule } from '@peca/design-system';
import { AllowToDirective, KeycloakCredentialsService } from '@peca/keycloak';

interface MenuModule {
  path: string;
  label: string;
  icon: string;
  roles: string[];
}

interface MenuGroup {
  name: string;
  modules: MenuModule[];
}

@Component({
  selector: 'peca-main-navigation-menu',
  templateUrl: './main-navigation-menu.component.html',
  styleUrls: ['./main-navigation-menu.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, AllowToDirective, DesignSystemModule],
})
export class MainNavigationMenuComponent {
  @Input()
  opened: boolean;
  expanded: boolean;
  groups: MenuGroup[];
  keycloakService: KeycloakCredentialsService;

  constructor() {
    this.keycloakService = inject(KeycloakCredentialsService);
    this.opened = false;
    this.expanded = true;

    this.groups = [
      {
        name: 'Gerencial',
        modules: [
          {
            path: 'diario',
            icon: 'today',
            label: 'Acompanhamento Diário',
            roles: [],
          },

          {
            path: 'receita',
            icon: 'trending_up',
            label: 'Acompanhamento Receita',
            roles: [],
          },

          {
            path: 'resultado',
            icon: 'stacked_bar_chart',
            label: 'Acompanhamento Resultado',
            roles: [],
          },

          {
            path: 'operacao',
            icon: 'view_kanban',
            label: 'Acompanhamento Operação',
            roles: [],
          },

          {
            path: 'produtos',
            icon: 'inventory_2',
            label: 'Acompanhamento Produtos',
            roles: [],
          },

          {
            path: 'entregas',
            icon: 'local_shipping',
            label: 'Acompanhamento Entregas',
            roles: [],
          },
        ],
      },
      {
        name: 'Comercial',
        modules: [
          {
            path: 'comercial',
            icon: 'store',
            label: 'Acompanhamento Comercial',
            roles: [],
          },

          {
            path: 'seller',
            icon: 'person',
            label: 'Acompanhamento Seller',
            roles: [],
          },

          {
            path: 'carrinho',
            icon: 'shopping_cart',
            label: 'Acompanhamento Carrinho',
            roles: [],
          },
        ],
      },
      {
        name: 'Técnico',
        modules: [
          {
            path: 'integracoes',
            icon: 'settings',
            label: 'Acompanhamento Integrações',
            roles: [],
          },

          {
            path: 'roadmap',
            icon: 'view_timeline',
            label: 'Roadmap Tecnologia',
            roles: [],
          },
        ],
      },
    ];
  }

  onClickToogleMenu() {
    this.expanded = !this.expanded;
  }

  onClickMenuItem() {
    this.expanded = false;
  }
}
